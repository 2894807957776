import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Section from "../components/Section";
import { Row, Col } from "reactstrap";
import PageTitleSection from "../components/PageTitleSection";
import truckImage from "../images/truck.png";

const WorkingWithENS = () => (
  <Layout>
    <SEO title="About" />
    <PageTitleSection title="WORKING WITH US" />
    <Section className="working-with-ens">
      <Row>
        <Col md={6}>
          <div className="wwu-item">
            <h4>Increase Supply Chain Efficiency</h4>
            <p>
              ENS Logistics provides technologically advanced solutions to
              increase supply chain efficiency. This allows our clients to focus
              on their business, while we take care of all the logistical
              details
            </p>
          </div>
        </Col>
        <Col md={6}>
          <div className="wwu-item alternate">
            <h4>Online Track and Trace</h4>
            <p>
              Status updates and real-time notifications for pick up, in
              transit, and delivery.
            </p>
          </div>
        </Col>
        <Col md={6}>
          <div className="wwu-item alternate">
            <h4>Document Imaging</h4>
            <p>
              Our customers have online access to their Proof of Delivery
              (POD’s) and bills of lading.
            </p>
          </div>
        </Col>
        <Col md={6}>
          <div className="wwu-item">
            <h4>Automatic Status Updates By Email</h4>
            <p>
              Email notifications are available when your shipments are picked
              up and delivered, giving real-time visibility into urgent
              shipments.
            </p>
          </div>
        </Col>
        <Col md={6}>
          <div className="wwu-item">
            <h4>On Time Reporting</h4>
            <p>
              Detailed Activity with Accessorial Charges Other customized
              reports as required
            </p>
          </div>
        </Col>
        <Col md={6}>
          <img src={truckImage} className="img-fluid" alt="" />
        </Col>
      </Row>
    </Section>
  </Layout>
);

export default WorkingWithENS;
